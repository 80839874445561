<template>
    <div class="about-wrapper">
        <div class="about-top"></div>
        <div class="about-main">
          <img class="img1" src="../assets/newImg/intro1.png" alt="">
          <img class="img2 mar" src="../assets/newImg/intro2.png" alt="">
          <img class="img3" src="../assets/newImg/intro3.png" alt="">
          <img class="img4" src="../assets/newImg/intro4.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {};
  },
  components: {
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.about-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #fff;
  .about-top {
    width: 100%;
    height: 64px;
    background: url("../assets/images/bg-mess.png") no-repeat;
    background-size: cover;
  }
  .about-main {
    padding: 15px 0 120px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    .img1{
      width: 352px;
    }
    .img2{
      width: 375px;
    }
    .img3{
      width: 375px;
    }
    .img4{
      width: 375px;
    }
    .mar{
      margin: 30px 0;
    }
  }
}
</style>